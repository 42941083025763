<template>
  <div class="reward-activity-info-block">
    <p class="card-title">{{ displayText.blockTitle }}</p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem :label="displayText.mode.label" prop="mode">
        <BaseElSelect v-model="formData.mode" :placeholder="displayText.select.placeholder" clearable @change="onTypeChange" :disabled="!!formData.orderCount">
          <BaseElSelectOption v-for="item in subscribePlanEquityTypeConfig" :key="item.value" :label="item.label" :value="item.value" />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem prop="name" v-if="showControl.equityPack">
        <template #label>
          <FormItemTooltipLabel :label="displayText.equityPack.label" :tooltip-width="200">
            {{ displayText.equityPack.tooltip }}
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect v-model="formData.equityPackId" :placeholder="displayText.select.placeholder" clearable :disabled="!!formData.orderCount">
          <BaseElSelectOption v-for="item in subscribeEquityPackList" :key="item.id" :label="item.name" :value="item.id" />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem prop="name" v-if="showControl.periodicBenefit">
        <template #label>
          <FormItemTooltipLabel :label="displayText.periodicBenefit.label" :tooltip-width="200">
            {{ displayText.periodicBenefit.tooltip }}
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect v-model="formData.equityPackId" :placeholder="displayText.select.placeholder" clearable :disabled="!!formData.orderCount">
          <BaseElSelectOption v-for="item in subscribeBenefitEquityPackList" :key="item.id" :label="item.name" :value="item.id" />
        </BaseElSelect>
      </BaseElFormItem>
      <div v-if="showControl.previewEquityPack" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[8px]">
            <div v-for="item in equityPackData" :key="item.id" class="flex text-gray-100 text-sub whitespace-pre-line gap-[20px]">
              <span class="font-medium flex-shrink-0" style="width: 95px">{{ item.label }}</span>
              <span class="font-normal">{{ item.value }}</span>
            </div>
            <p class="text-gray-80 text-sm">{{ displayText.equityPack.warning }}</p>
          </div>
        </GrayBlockContainer>
      </div>
      <div v-if="showControl.previewPeriodicBenefit" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[8px]">
            <div v-for="(item, idx) in displayPeriodicBenefitData" :key="idx" class="flex text-gray-100 text-sub whitespace-pre-line gap-[20px]">
              <span class="font-medium flex-shrink-0" style="width: 75px">{{ item.label }}</span>
              <span class="font-normal">{{ item.value }}</span>
            </div>
            <div class="flex items-center text-gray-100 text-sub whitespace-pre-line gap-[20px]">
              <span class="font-medium flex-shrink-0" style="width: 75px">{{ displayText.description }}</span>
              <BaseElButton class="underline" type="text" size="small" style="padding: 0; font-size: 14px;" @click="modal.equityPackDescription = true">{{ displayText.btn.view }}</BaseElButton>
            </div>
          </div>
        </GrayBlockContainer>
      </div>
    </BaseElForm>

    <BaseDialog
      v-if="modal.equityPackDescription"
      :title="displayText.dialog.title"
      width="560px"
      hideCancel
      @confirm="modal.equityPackDescription = false"
      @close="modal.equityPackDescription = false"
    >
      <div>
        <GrayBlockContainer style="margin-bottom: 40px;">
          <div v-html="selectEquityPackDescription" />
        </GrayBlockContainer>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { get, find, map, reduce, join, filter } from 'lodash'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useSubscribe } from '@/use/useSubscribe'
import { GetPeriodicBenefitTemplate } from '@/api/periodicBenefit'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { subscribePlanEquityTypeConfig } from '@/config/subscribe'

export default defineComponent({
  name: 'SubscribeCombineBlock',
  components: { FormItemTooltipLabel, GrayBlockContainer, BaseDialog },
  props: ['FormsContext', 'planData'],
  setup (props) {
    const { getSubscribeEquityPack, subscribeEquityPackList } = useSubscribe()
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const { formatPeriodPreviewText } = usePeriodBenefitTemplate()
    const subscribeBenefitEquityPackList = ref([])

    const formRef = ref(null)
    const formData = reactive({
      mode: null,
      equityPackId: null,
      orderCount: 0,
    })
    const formRules = {}
    const modal = reactive({
      equityPackDescription: false,
    })
    const showControl = computed(() => {
      const controls = {
        equityPack: formData.mode === 'default',
        periodicBenefit: formData.mode === 'custom',
        previewEquityPack: formData.equityPackId && formData.mode === 'default',
        previewPeriodicBenefit: formData.equityPackId && formData.mode === 'custom',
      }
      return controls
    })
    const displayText = computed(() => {
      const data = {
        blockTitle: '訂閱品項',
        mode: {
          label: '訂閱品項類型',
        },
        equityPack: {
          label: '選擇品項組合',
          tooltip: '如需新增選項，請至「品項組合」新增',
          warning: '系統不會自動寄出品項。如會員訂閱此方案，可至「訂閱模組 > 訂閱紀錄」查看每筆訂單',
        },
        periodicBenefit: {
          label: '選擇會員權益範本',
          tooltip: '如需新增選項，請至「會員權益模組 > 會員權益範本管理」新增屬性為「訂閱商品」的會員權益範本'
        },
        select: {
          placeholder: '請選擇',
        },
        btn: {
          view: '查看',
        },
        description: '商品描述',
        dialog: {
          title: '會員權益商品描述',
        }
      }
      return data
    })
    const selectEquityPackDescription = computed(() => {
      const equityPack = find(subscribeBenefitEquityPackList.value, { id: formData.equityPackId })
      return get(equityPack, 'description')
    })

    const displayPeriodicBenefitData = computed(() => {
      const equityPack = find(subscribeBenefitEquityPackList.value, { id: formData.equityPackId })
      const periodConfig = get(equityPack, 'period')
      const startType = periodConfig.startType
      const periodType = periodConfig.type
      const instantAward = get(equityPack, 'instantAward')
      let fixedWeekDay = '-'
      const nDayValue = periodConfig.nDayValue || '-'
      const fixedValue = periodConfig.fixedValue || '-'
      const totalAwardCount = get(equityPack, 'totalAwardCount', '-')
      if (periodType === 'weekly' && startType === 'fixed') {
        fixedWeekDay = fixedValue
      }

      const entitlements = join(map(get(equityPack, 'entitlements', '-'), (item) => {
        const itemData = get(item, 'StandardAward', {})
        if (itemData.type === 'classTicket') {
          return `${itemData.name}（${itemData.amount} 份）`
        } else if (itemData.type === 'coupon') {
          return `${itemData.name}（${itemData.amount} 張）`
        } else if (itemData.type === 'pointCard') {
          return `${itemData.name}（${itemData.amount} 張）`
        } else if (itemData.type === 'punchCard') {
          return `${itemData.name}（${itemData.amount} 份）`
        } else if (itemData.type === 'wallet') {
          return `儲值金（${itemData.amount} 元）`
        } else if (itemData.type === 'cashback') {
          return `回饋金（${itemData.amount} 元）`
        } else if (itemData.type === 'point') {
          return `點數（${itemData.amount} 點）`
        }
      }), '\n')

      return [
        { label: '權益項目', value: entitlements },
        {
          label: '發放週期',
          value: formatPeriodPreviewText({
            startType,
            periodType,
            fixedWeekDay,
            nDayValue,
            fixedValue,
            instantAward,
            totalAwardCount,
          }, { highLight: false }),
        },
        { label: '發放總次數', value: totalAwardCount },
      ]
    })

    const equityPackData = computed(() => {
      const equityPack = find(subscribeEquityPackList.value, { id: formData.equityPackId })
      const itemsName = map(get(equityPack, 'equities'), 'name').join('、')
      const sum = reduce(get(equityPack, 'equities'), (total, equity) => total + get(equity, 'price', 0), 0)
      return [
        { label: '品項組合名稱', value: get(equityPack, 'name') },
        { label: '預估售價', value: `${sum} 元` },
        { label: '品項內容', value: itemsName },
      ]
    })

    const getSubscribeBenefitEquityPack = async () => {
      await fetchAll(GetPeriodicBenefitTemplate, {
        shopId: shopId.value,
      }, (res) => {
        subscribeBenefitEquityPackList.value = filter(res, { type: 'subscription' })
      })
    }

    const syncData = () => {
      const data = props.planData
      formData.mode = get(data, 'planEquityPack.equityPack.mode')
      if (formData.mode === 'custom') {
        formData.equityPackId = get(data, 'planEquityPack.equityPack.customOptions.customEquityPackId')
      } else if (formData.mode === 'default') {
        formData.equityPackId = get(data, 'planEquityPack.equityPack.id')
      }
      formData.orderCount = get(data, 'orderCount')
    }
    const onTypeChange = () => {
      formData.equityPackId = null
    }

    const compactData = computed(() => {
      let data
      const mode = get(formData, 'mode')
      const equityPackId = get(formData, 'equityPackId')
      if (equityPackId) {
        if (mode === 'custom') {
          data = {
            equityOptions: {
              mode,
              customEquityPackOptions: {
                customEquityPackId: equityPackId,
                customEquityPackType: 'periodicBenefitTemplate',
              },
            }
          }
        } else if (mode === 'default') {
          data = { equityOptions: { mode, equityPackId }}
        }
      } else {
        data = {
          equityOptions: null,
        }
      }
      return data
    })
    onMounted(async () => {
      await nextTick()
      await getSubscribeBenefitEquityPack()
      await getSubscribeEquityPack()
      props.FormsContext.setFormRef('equityPack', formRef.value)
      props.FormsContext.setFormData('equityPack', { equityOptions: null })

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('equityPack', { ...compactData.value })
    })

    return {
      modal,
      formRef,
      formData,
      formRules,
      equityPackData,
      subscribeBenefitEquityPackList,
      subscribeEquityPackList,
      GetPeriodicBenefitTemplate,
      displayPeriodicBenefitData,
      selectEquityPackDescription,
      subscribePlanEquityTypeConfig,
      onTypeChange,
      displayText,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content, .previewArea {
  @apply w-[560px]
}
</style>
